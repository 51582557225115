@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}
body {
  --scroll: 0px;
}

.background-design {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    135deg,
    rgba(255, 192, 203, 0.2) 25%,
    rgba(173, 216, 230, 0.2) 25%,
    rgba(173, 216, 230, 0.2) 50%,
    rgba(255, 192, 203, 0.2) 50%,
    rgba(255, 192, 203, 0.2) 75%,
    rgba(173, 216, 230, 0.2) 75%
  );
  background-size: 100px 100px;
  animation: moveBackground 5s linear infinite;
}
html {
  scroll-behavior: smooth;
}

@keyframes moveBackground {
  0% {
    background-position: 0 var(--scroll);
  }
  100% {
    background-position: 100px calc(var(--scroll) + 100px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.3s ease-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out;
}